import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* Login img caption block */
    }
    <div {...{
      "className": "mb-3"
    }}>{`
  `}<div parentName="div" {...{
        "className": "image-frame"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1280px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABnMzHjqxgr//EABoQAAICAwAAAAAAAAAAAAAAAAECABIDE0L/2gAIAQEAAQUCGU1F2lpztKKVn//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPwHVf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEv/aAAgBAgEBPwGs1//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARARIf/aAAgBAQAGPwKNSNtHD//EABwQAAICAgMAAAAAAAAAAAAAAAERACExYUFRkf/aAAgBAQABPyEelimoGLY3CfK8jsOjAoQjEeWBU//aAAwDAQACAAMAAAAQ68//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EG4wc5T/xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhUf/aAAgBAgEBPxBMEy4P/8QAHBABAAMBAAMBAAAAAAAAAAAAAQARITFBUWGB/9oACAEBAAE/EKoLAfRg7Xo08fD9loU1Lb1BjagwBRZGuk1RR2zXzP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/7f61c/jake-arlyn-prizecard.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/d00b9/jake-arlyn-prizecard.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/af3f0/jake-arlyn-prizecard.webp 1280w"],
              "sizes": "(max-width: 1280px) 100vw, 1280px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/066f9/jake-arlyn-prizecard.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/4b190/jake-arlyn-prizecard.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/eea4a/jake-arlyn-prizecard.jpg 1280w"],
              "sizes": "(max-width: 1280px) 100vw, 1280px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a9d093716f930993e193dbaa34da1a88/eea4a/jake-arlyn-prizecard.jpg",
              "alt": "jake arlyn prizecard",
              "title": "jake arlyn prizecard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>{`
  `}</div>{`
  `}<span parentName="div" {...{
        "className": "caption-text"
      }}>{`PayPerks Founders, Arlyn and Jake, on the right, with their prize!`}</span>
    </div>
    <p>{`The award was determined by a live vote of hundreds of financial services executives and fellow innovators at the `}<a parentName="p" {...{
        "href": "http://www.americanbanker.com/conferences/cfsi/"
      }}>{`CFSI Underbanked Financial Services Forum`}</a>{`.`}</p>
    <p>{`While it`}{`’`}{`s always encouraging to be recognized, this award was particularly meaningful as we were identified in the company of other start-ups that are focused on solving the unique pain points of the emerging middle class. It’s an honor to share the stage with other great companies such as `}<a parentName="p" {...{
        "href": "https://www.lendup.com/"
      }}>{`LendUp`}</a>{` and `}<a parentName="p" {...{
        "href": "http://www.miteksystems.com/"
      }}>{`Mitek`}</a>{`.`}</p>
    <p>{`The Mega Challenge kicked off an incredible conference that shined a spotlight on innovative new technologies and services driving meaningful change for consumers. Congrats to the CFSI Team on exceeding expectations, once again. We’re already looking forward to next year's conference "Emerge: The Forum on Consumer Financial Services Innovation" in LA!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      